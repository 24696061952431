@font-face {
  font-family: "ZombiePunksRegular";
  src: url("./fonts/ZombiePunksRegular.ttf");
}

@font-face {
  font-family: "ZombiePunksAlternative";
  src: url("./fonts/ZombiePunksAlternative.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
/* * {
  font-family: 'Poppins' 'sans-serif';
} */


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}





